<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <SelectorCaseStudyStatus
      dark
      autoload
      label="Case Study Status"
      helpText="This is an actual Case Study Status"
      return-object
      v-model="form.status"
    >
    </SelectorCaseStudyStatus>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.searchableKeyword.name"
      label="Keywords"
      :helpText="'This text will be used for the recommendations purpose, please make sure that it is unique and valid'"
      required
      :textarea="true"
    ></HelpFormInputVue>

    <!-- Slides Configurations -->
    <FormSection
      :label="'Slides'"
      :icon="'mdi-play-box-outline'"
      underline
      :editable="false"
      right
    ></FormSection>

    <SelectorDefault
      dark
      label="Slide Image"
      v-model="form.slide_image_key"
      :items="slideImages"
      :helpText="'Please Select an Image that will be used in the presentations'"
      required
      clearable
      hide-details
      class="my-6"
    ></SelectorDefault>

    <v-alert prominent type="info" color="blue-grey">
      <v-row align="center">
        <v-col class="grow">
          Uploaded slides are using in
          <strong>automated proposal generation</strong>. Please make sure that
          they are in a good quality and are in alignment with corporate design.
        </v-col>
      </v-row>
    </v-alert>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      centered
      dark
      icons-and-text
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#dark">
        Dark
        <v-icon>mdi-moon-full</v-icon>
      </v-tab>

      <v-tab href="#light">
        Light
        <v-icon>mdi-moon-new</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items class="transparent pa-2" v-model="tab">
      <v-tab-item class="transparent" :key="'dark'" :value="'dark'">
        <VueFileAgent
          deletable
          :linkable="true"
          :multiple="false"
          accept=".ppt, .pptx"
          v-model="darkSlide"
          :editable="false"
          :uploadUrl="getUploadURL('SLIDES-DARK')"
          :uploadHeaders="uploadHeaders"
          @upload="() => $emit('onAfterUpload')"
          @delete="(...props) => $emit('onFileDelete', 'SLIDES-DARK', ...props)"
        ></VueFileAgent>
      </v-tab-item>
      <v-tab-item class="transparent" :key="'light'" :value="'light'">
        <VueFileAgent
          deletable
          :linkable="true"
          :multiple="false"
          accept=".ppt, .pptx"
          v-model="lightSlide"
          :editable="false"
          :uploadUrl="getUploadURL('SLIDES-LIGHT')"
          :uploadHeaders="uploadHeaders"
          @upload="() => $emit('onAfterUpload')"
          @delete="
            (...props) => $emit('onFileDelete', 'SLIDES-LIGHT', ...props)
          "
        ></VueFileAgent>
      </v-tab-item>
    </v-tabs-items>
  </v-form>
</template>
        
        
  <script>
import { CASE_STUDIES_SLIDES_IMAGES } from "../../../../../../constants/defaults/SlidesImages";
import SelectorCaseStudyStatus from "../../../../../atoms/common/autocompletes/SelectorCaseStudyStatus.vue";
import SelectorDefault from "../../../../../atoms/common/autocompletes/SelectorDefault.vue";
import FormSection from "../../../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    uploadURL: {
      type: String,
      default: "",
    },
    uploadHeaders: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const { darkSlide, lightSlide } = this.prepareFiles(this.value);

    return {
      // Form
      pValid: this.valid,
      form: this.value,

      darkSlide,
      lightSlide,

      // ======rules

      linkRules: [
        (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "Link should be a proper link",
      ],
      tab: "dark",

      slideImages: CASE_STUDIES_SLIDES_IMAGES,
    };
  },
  components: {
    HelpFormInputVue,
    FormSection,
    SelectorCaseStudyStatus,
    SelectorDefault,
  },
  methods: {
    getUploadURL(type) {
      return `${this.uploadURL}/${type}/upload`;
    },
    prepareFiles(value) {
      let darkSlide = value.darkSlide || [];
      let lightSlide = value.lightSlide || [];

      return { darkSlide, lightSlide };
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value: {
      handler(newVal) {
        const { darkSlide, lightSlide } = this.prepareFiles(newVal);

        this.darkSlide = darkSlide;
        this.lightSlide = lightSlide;

        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
        
        
  <style lang="scss" scoped>
.term-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>