<template>
  <AView
    :value="value"
    :active="caseStudy.id === activeCaseStudyId"
    :name="caseStudy.name"
    ref="caseStudy-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
  >
    <template v-slot:content="{}">
      <v-card class="transparent mt-4 pa-2 elevation-0">
        <v-card-text>
          <CaseStudySettingsEditorForm
            ref="form"
            v-model="caseStudy"
            :uploadURL="getUploadUrl()"
            :uploadHeaders="uploadHeaders"
            @onAfterUpload="onAfterUpload"
            @onFileDelete="onFileDelete"
          ></CaseStudySettingsEditorForm>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
          
          
      <script>
import { mapGetters, mapState } from "vuex";
import { KnowledgeBaseAPIInstance } from "../../../../../../components/api";
import CaseStudySettingsEditorForm from "../../../../../../components/wad/organisms/knowledgeBase/caseStudies/forms/editor/CaseStudySettingsEditorForm.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    //   FormSection,
    CaseStudySettingsEditorForm,
  },
  data() {
    return {
      updatedTimeout: undefined,

      caseStudy: {
        searchableKeyword: {},
      },

      uploadHeaders: KnowledgeBaseAPIInstance.CaseStudies.uploadHeaders,
    };
  },
  computed: {
    ...mapState("CaseStudyStore", [
      "activeCaseStudyId",
      "displayedCaseStudies",
    ]),
    ...mapGetters("CaseStudyStore", ["caseStudyById"]),
  },
  created() {
    this.$store.dispatch("CaseStudyStore/GetCaseStudy", {
      id: this.value.relation.params.caseStudyId,
    });

    this.caseStudy = this.caseStudyById(this.value.relation.params.caseStudyId);
  },
  methods: {
    onAfterUpload() {
      this.$store.dispatch("CaseStudyStore/GetCaseStudy", {
        id: this.value.relation.params.caseStudyId,
        refresh: true,
      });
    },
    onFileDelete(type, file) {
      this.$store.dispatch("CaseStudyStore/DeleteCaseStudyAttachment", {
        caseStudy: this.caseStudy,
        file,
        type,
      });
    },
    getUploadUrl() {
      return KnowledgeBaseAPIInstance.CaseStudies.getUploadUrl(this.caseStudy);
    },
    onHover() {
      this.$store.commit(
        "CaseStudyStore/setActiveId",
        this.value.relation.params.caseStudyId
      );
    },
  },
  watch: {
    displayedCaseStudies: {
      async handler(newVal) {
        if (newVal) {
          this.caseStudy = this.caseStudyById(
            this.value.relation.params.caseStudyId
          );
        }
      },
      deep: true,
    },
    caseStudy: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("CaseStudyStore/UpdateCaseStudy", {
            caseStudy: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>